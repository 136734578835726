.bg-main, .page-home .home-block-bottom .home-actus {
  background-color: #ae2c35;
}

.bg-text {
  background-color: #202020;
}

html, body {
  height: 100%;
  width: 100%;
  font-size: .625em;
  /* fallback IE8+ */
  font-size: calc(1em * .625);
  /* IE9-IE11 math fixing. See http://bit.ly/1g4X0bX */
  font-family: "Arial, sans-serif", sans-serif;
  font-weight: lighter;
  color: #202020;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-size: 1.4rem;
  position: relative;
  font-weight: normal;
}

@media (max-width: 1023px) {
  #app {
    display: inline-block;
  }
}

a {
  color: inherit;
  cursor: pointer;
  border: none;
}

img {
  max-width: 100%;
}

ul, li {
  list-style-type: none;
}

.fullwidth {
  width: 100%;
  margin: 0;
}

.fullheight {
  height: 100%;
}

.innerwidth {
  width: 80%;
  max-width: 1660px;
}

@media (max-width: 1023px) {
  .innerwidth {
    width: 100%;
    max-width: none;
    padding: 0 2rem;
  }
}

.pos-absolute {
  position: absolute;
}

.pos-relative {
  position: relative;
}

.centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.nomargin-bottom {
  margin-bottom: 0 !important;
}

.nomargin-top {
  margin-top: 0 !important;
}

.txt-center {
  text-align: center;
}

.txt-right {
  text-align: right;
}

.txt-thin {
  font-weight: 100;
}

.txt-bold {
  font-weight: 400;
}

.txt-black {
  font-weight: 700;
}

.fs-normal {
  font-style: normal;
}

.fs-italic {
  font-style: italic;
}

.flex-break {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  width: 0px;
  height: 0px;
  overflow: hidden;
}

.pointer {
  cursor: pointer;
}

.clear {
  clear: both;
}

button,
a.button,
.page-actualites .actus ul li > div a {
  outline: none;
  background-color: #ae2c35;
  color: #fff;
  text-align: center;
  padding: 1.2rem 3rem;
  font-size: 1.2rem;
  border: none;
  font-weight: normal;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
}

#app {
  position: relative;
}

.site-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-align: center;
      align-items: center;
  padding: 3rem 0;
}

@media (max-width: 1023px) {
  .site-header {
    display: block;
    padding-left: 3rem;
  }
}

.site-header .header-items {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: end;
      justify-content: flex-end;
  -ms-flex: 1;
      flex: 1;
}

@media (max-width: 1023px) {
  .site-header .header-items {
    display: none;
  }
}

.site-header .burger-icon {
  display: none;
  position: absolute;
  top: 3rem;
  right: 3rem;
  width: 3rem;
  height: 3rem;
}

@media (max-width: 1023px) {
  .site-header .burger-icon {
    display: block;
  }
}

.site-header .burger-icon svg path {
  fill: #ae2c35;
}

.site-header .header-items-mobile {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: end;
      justify-content: flex-end;
  -ms-flex: 1;
      flex: 1;
}

.adresse,
.tel {
  padding: 0 3rem;
  border-right: solid 1px #c1c1c1;
}

@media (max-width: 1023px) {
  .adresse,
  .tel {
    margin: 2rem 0;
  }
}

.adresse img,
.tel img {
  display: inline-block;
  vertical-align: top;
  margin-right: 0.4rem;
}

.adresse p,
.tel p {
  display: inline-block;
  text-align: left;
  color: #555454;
  font-weight: bold;
  font-size: 1.2rem;
}

.contact-btn {
  margin: 0 3rem;
  text-transform: uppercase;
  vertical-align: middle;
  padding: 0.9rem 1.2rem;
}

@media (max-width: 1023px) {
  .contact-btn {
    display: block;
    width: 80%;
    margin: 2rem auto;
  }
}

.contact-btn img {
  vertical-align: bottom;
}

.newsletter-btn {
  padding: 0.95rem 1.2rem;
  background-color: #000;
  color: #fff;
}

@media (max-width: 1023px) {
  .newsletter-btn {
    display: block;
    width: 80%;
    margin: 0 auto;
  }
}

.site-menu {
  background-color: #202020;
  color: #fff;
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}

@media (max-width: 1023px) {
  .site-menu {
    display: none;
  }
}

.site-menu .menulinks {
  padding: 1.4rem 0;
}

.site-menu .menulinks a {
  font-size: 1.4rem;
  text-decoration: none;
  padding: 0 1.5rem;
  border-left: solid 1px #fff;
}

.site-menu .menulinks a:first-child {
  border-left: none;
}

.menu-burger-modal .menulinks a {
  display: block;
  margin: 1rem auto;
  text-align: center;
}

.txt-search {
  border: none;
  color: #fff;
  padding: 0 2rem;
  outline: none;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPCAYAAADphp8SAAABBUlEQVQ4jZ2TvUpDQRBGj7e4Kexs1RfQQrQzRSrBxlJ8CNH4ZoJoo1jfKwT8w15EbeziTyXkWDjBFbPJjQNfszN7dr+dWVRCpdpVa/UtVMdamdSN1IwKMA+cACvAGVADAuvAJnADbAHP5CJOu1Jf1M6I0zqRu1ZbuRuh7quDDCSFDaI2a60GPoCN7LW/4xyYDbt/ogDWgGoChKhZzSWLBoA0PseBLoF2A0gbuMtmY06aPnZ3XNda0dpJ7e+rj+pO04E8BS74PZC3wBywGEaOgV3gKbWWfpEDtae+h3qxVqpLauVPvKp7ajG0No2K2NxPgJW6PC1oqAX1KIHd/xc01Lb6oB5+AW5ZKRnPcTZaAAAAAElFTkSuQmCC) right 1rem center no-repeat #555454;
}

.txt-search:-ms-input-placeholder {
  color: #fff;
}

.txt-search::placeholder {
  color: #fff;
}

@media (max-width: 1023px) {
  .txt-search {
    margin: 2rem auto;
    display: block;
    padding: 1rem;
    width: 80%;
    background-color: grey;
  }
}

.footer .line {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  text-align: center;
  -ms-flex-pack: center;
      justify-content: center;
  border-top: solid 1px #aeadad;
  margin: 4rem 0;
  padding-top: 4rem;
}

@media (max-width: 1023px) {
  .footer .line {
    display: block;
  }
}

.footer .line a {
  text-decoration: none;
  padding: 0rem 2rem;
  display: inline-block;
  border-left: solid 1px #202020;
  color: #555454;
}

@media (max-width: 1023px) {
  .footer .line a {
    display: block;
    border: none;
  }
}

.footer .line a:first-child {
  border-left: none;
}

.footer label {
  text-align: center;
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 3rem;
}

.twocolslayout {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: center;
      align-items: center;
}

@media (max-width: 1023px) {
  .twocolslayout {
    display: block;
  }
}

.twocolslayout:nth-child(even) {
  -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
}

.twocolslayout__item {
  width: 50%;
}

@media (max-width: 1023px) {
  .twocolslayout__item {
    width: 100%;
  }
}

.twocolslayout__item--body {
  -ms-flex-positive: 9999;
      flex-grow: 9999;
}

.twocolslayout__item--figure {
  margin-right: auto;
  -ms-flex-positive: 1;
      flex-grow: 1;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.page-head {
  padding: 5rem 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-bottom: 3rem;
}

.page-head .head-title {
  display: inline-block;
  margin-left: 50%;
  transform: translateX(-50%);
  width: auto;
  padding: 3rem 4rem;
  color: #fff;
  background-color: #ae2c35;
  text-align: center;
  max-width: 50rem;
}

@media (max-width: 1023px) {
  .page-head .head-title {
    display: block;
    margin: 0;
    transform: none;
    max-width: none;
  }
}

.page-head .head-title.dark {
  background-color: #202020;
}

.page-head .head-title.dark h1 {
  color: #ae2c35;
}

.page-head .head-title h1 {
  font-size: 3.5rem;
  margin-bottom: 2rem;
  font-weight: normal;
  text-transform: uppercase;
}

@media (max-width: 1023px) {
  .page-head .head-title h1 {
    font-size: 1.8rem;
  }
}

.page-head .head-title h2 {
  font-size: 1.5rem;
  font-weight: normal;
  text-transform: uppercase;
}

@media (max-width: 1023px) {
  .page-head .head-title h2 {
    font-size: 1.6rem;
  }
}

.page-intro {
  text-align: center;
}

.page-intro h3, .page-intro h2 {
  color: #000;
  font-weight: normal;
  font-size: 1.4rem;
  text-transform: uppercase;
}

.page-intro h3::first-letter, .page-intro h2::first-letter {
  font-size: 1.8rem;
}

.page-intro h3::after, .page-intro h2::after {
  content: '';
  background-color: #ae2c35;
  width: 6.5rem;
  height: 0.4rem;
  display: block;
  margin: 1rem auto;
}

.page-intro p {
  display: block;
  margin: 3rem auto;
  max-width: 60%;
  text-align: center;
  line-height: 2.2rem;
  font-size: 1.4rem;
}

@media (max-width: 1023px) {
  .page-intro p {
    margin: 1rem auto;
    max-width: none;
  }
}

.separ-title {
  display: -ms-flexbox;
  display: flex;
  max-width: 70%;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-line-pack: center;
      align-content: center;
  margin: 6rem auto 4rem auto;
}

@media (max-width: 1023px) {
  .separ-title {
    max-width: 100%;
    margin: 4rem auto;
  }
}

.separ-title div {
  -ms-flex: 1;
      flex: 1;
  border-top: solid 2px #e6e6e6;
}

@media (max-width: 1023px) {
  .separ-title div {
    display: none;
  }
}

.separ-title label {
  white-space: nowrap;
  display: inline-block;
  padding: 0 3rem;
  font-size: 2rem;
  font-weight: bold;
  line-height: 0;
}

@media (max-width: 1023px) {
  .separ-title label {
    padding: 0;
    white-space: normal;
    line-height: normal;
    text-align: center;
  }
}

.separ-title.bold div {
  border-top: solid 3px #000;
}

.separ-title.maincolor div {
  border-color: #ae2c35;
}

.separ-title.maincolor label {
  color: #ae2c35;
}

.zoomable, .photo-gallery ul li, .page-realisations .reals ul li {
  position: relative;
}

.zoomable::before, .photo-gallery ul li::before, .page-realisations .reals ul li::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0.0;
  transition: all ease-in 300ms;
}

.zoomable::after, .photo-gallery ul li::after, .page-realisations .reals ul li::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAAwCAYAAABe6Vn9AAAD5klEQVRogdWaPYgdVRTHf293g7wV90OCmJUUih/gggqxsxB1tVARREQTRMSAlWJhsUkl2IigEWMluBrETWwMBAVFzYqVFqZI0CaxsXNt3n4UCZj1ZzH34Th75+vNvN3nHw7M3DfnnPu/M/fcc+59HZWa6AIPBrkLuB2YDrIe5CJwAfgeOAtcrutkYKhVZV5dUtesh7Wgd2cNXwNLlYf2qSfVrZpEstgKdvbtJqGD6npDIlmsq4d2mlBHPdYykSyOBT9DJ9RRT5R0Zk39yGSk71Cng+50uD8Ufi+bb5+o48Mm9E5BB3rqUXWyooPJ8HyvwOb7wyT0bIHjU+reAR3tNQkIeTg4DEJzxgPA3+piSw4Xg70s1tWb2ia0nDN6R9oavSBHcvwst0lo3vg6c6plMn2JfX5boR+tEFqKOPhTnR0SoZlgP4sP2yA0aXzuvDIkMn15OeJzw+oRNJfQYxHDa2q3hqHFlG5Vna7xderxJoTGQtacxekdyJAvA59H2h9oYnSMpATI4rsmRmsg5ufuJgYngNsi7edL9GaBA6n7W1LXC6nrHnCuwM6FSFusP9VhPCBMlXyrCxGdGL4tsTMV0dloOoemIjw3Go1SdcT8XNfE4EQwmiU1leOsj3PAw6n7p4GXwnW6vVfiP9b5zRKdQkwEp1lC+4FfC/R6/HdCp+dTnYCyP8f2wBgDLkXaG0WaGoj5ifWnMsaIR5qFSNswEPNTFmGLEVbmLOpmCoNI13jh1zhTWGH7RJwGDjcaqXK8CMxk2jZDfwZHYBbLtldNsuJhvJ2ZYD+Lpaa2+xd59dDJIRGKFZOt1kN5TnTnKtar6iX1DXVPG4R2c08hi6/Va5sSwmT3JQ/LNtv1+bQCkTR+VK9vSgj13QInPZNPpmpV2Q3PF+3LFeEXa+4GdXTbcUoHOAE8XxAc10mKs7PAz8Af/JsT3gjcCzwEPEWyBDTB78AjJEc05chhOq6+N+CoVsVmjWdX1QNWeENjOTy3gFeB52iY/UawGex+UEPnBpIFt7w8r8B6znbPh+aC3Ul1paaNK+qTRf2tE0HmTU4UNmp2YiPoxRbNa9TTNe1dVQ/n9TMWFMrQJZnw2TPWflGYPmNdofyMdZzk86uTOwocBd7a/stg60rb0lHfrPmmVN82c2i220Sy8prVMok0PlYnRpUQ6gvqXzVJnTHUb4PMoZ3AE8BnJPO1Kn4AHh1VQgD3A2eol2kcH2VCAPcAX5GkU1WwOuqEAG4FvgFurvDslbzUZ5TwG3Af8d2pLM7/H95QH7PAFyTk8vDMbofoujKpfpkTuo87outQmexRX1cvmpQgP5n6n8M/1nXUJKjoGOoAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.0;
  transform: scale(0.5);
  transition: all ease-out 200ms;
}

.zoomable:hover::before, .photo-gallery ul li:hover::before, .page-realisations .reals ul li:hover::before {
  opacity: 1.0;
}

.zoomable:hover::after, .photo-gallery ul li:hover::after, .page-realisations .reals ul li:hover::after {
  opacity: 1.0;
  transform: scale(1);
}

.photo-gallery ul {
  margin: 0 auto;
  padding: 0;
  display: block;
}

@media (max-width: 1023px) {
  .photo-gallery ul {
    transform: none;
    margin-left: 0;
  }
}

.photo-gallery ul li {
  display: inline-block;
  width: 33%;
  height: calc(100vh / 4);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin: -0.21rem;
  cursor: pointer;
}

.photo-gallery .gallery-overlay {
  position: fixed;
  display: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.4);
}

.photo-gallery .gallery-container {
  position: fixed;
  display: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.photo-gallery .gallery-rel {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-align: center;
      align-items: center;
  width: 100%;
  height: 100%;
  padding: 5%;
}

@media (max-width: 1023px) {
  .photo-gallery .gallery-rel {
    padding: 0;
  }
}

.photo-gallery .gallery-rel button {
  background-color: #000;
  color: #fff;
  font-size: 4rem;
  padding: 3rem;
}

@media (max-width: 1023px) {
  .photo-gallery .gallery-rel button {
    font-size: 2rem;
    padding: 1rem;
  }
}

.photo-gallery .gallery-rel .gallery-pic {
  -ms-flex: 1;
      flex: 1;
  padding: 3rem;
  text-align: center;
  position: relative;
}

@media (max-width: 1023px) {
  .photo-gallery .gallery-rel .gallery-pic {
    padding: 0;
  }
}

.photo-gallery .gallery-rel .gallery-pic button {
  position: absolute;
  top: -1rem;
  right: -5rem;
  font-size: 2rem;
  text-align: center;
  padding: 1rem 1.5rem;
  display: inline-block;
  background-color: #ae2c35;
  color: #fff;
}

@media (max-width: 1023px) {
  .photo-gallery .gallery-rel .gallery-pic button {
    position: fixed;
    top: 0;
    right: 0;
    font-size: 1rem;
  }
}

.photo-gallery .gallery-rel .gallery-pic img {
  max-width: 100%;
}

.form-newsletter {
  padding-top: 4rem;
  padding-bottom: 3rem;
}

.form-newsletter h4 {
  font-size: 2rem;
}

.form-newsletter p {
  line-height: 2rem;
  margin-top: 0.6rem;
}

.page-home .home-slider {
  position: relative;
  height: 500px;
}

@media (max-width: 1023px) {
  .page-home .home-slider {
    height: 15vh;
  }
}

.page-home .home-slider .slidercontent {
  padding: 4rem 2rem;
  background-color: rgba(0, 0, 0, 0.6);
  display: inline-block;
  max-width: 40%;
  position: absolute;
  bottom: 11rem;
  z-index: 1;
}

@media (max-width: 1023px) {
  .page-home .home-slider .slidercontent {
    display: none;
  }
}

.page-home .home-slider .slidercontent h1 {
  color: #fff;
  font-size: 3.5rem;
  font-weight: normal;
  display: block;
  margin-bottom: 1rem;
}

.page-home .home-slider .slidercontent h2 {
  color: #fff;
  font-size: 1.4rem;
  font-weight: normal;
  display: block;
  margin-bottom: 2rem;
}

.page-home .home-slider .slidercontent .button, .page-home .home-slider .slidercontent .page-actualites .actus ul li > div a, .page-actualites .actus ul li > div .page-home .home-slider .slidercontent a {
  font-size: 1.5rem;
}

.page-home .home-slider .sliderpics {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.page-home .home-slider .sliderpics .pics-container {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.page-home .home-slider .sliderpics .pic {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover !important;
  z-index: 0;
}

.page-home .home-slider .sliderpics .pic-1 {
  background: url(../images/home-slider-1.e25088fc2718ee9a685d.png) center center no-repeat;
}

.page-home .home-slider .sliderpics .pic-2 {
  background: url(../images/home-slider-2.bd0a8483f763a4e87dce.png) center center no-repeat;
}

.page-home .home-slider .sliderpics .pic-3 {
  background: url(../images/home-slider-3.dbf999e49e5363f9adfa.png) center center no-repeat;
}

.page-home .slidercontent-mobile {
  display: none;
  padding: 2rem;
}

.page-home .slidercontent-mobile h1 {
  font-size: 2rem;
}

.page-home .slidercontent-mobile h2 {
  font-size: 1.4rem;
  margin: 1rem 0;
}

@media (max-width: 1023px) {
  .page-home .slidercontent-mobile {
    display: block;
  }
}

.page-home .homeblocks {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

@media (max-width: 1023px) {
  .page-home .homeblocks {
    margin-top: 3rem;
  }
}

.page-home .homeblocks h3 {
  text-transform: uppercase;
  display: block;
  margin-bottom: 2rem;
}

.page-home .homeblocks p {
  line-height: 2rem;
  margin-bottom: 2rem;
}

.page-home .homeblocks .homeblock-red {
  background-color: #ae2c35;
  padding-left: calc(100% - 90%) !important;
}

.page-home .homeblocks .homeblock-black {
  background-color: #202020;
  padding-right: calc(100% - 90%) !important;
}

.page-home .homeblocks .homeblock-black + .twocolslayout__item {
  background-position: right center;
}

.page-home .homeblocks .twocolslayout {
  -ms-flex-align: stretch;
      align-items: stretch;
}

.page-home .homeblocks .twocolslayout__item {
  padding: 2rem;
  background-repeat: no-repeat;
  background-size: contain;
}

@media (max-width: 1023px) {
  .page-home .homeblocks .twocolslayout__item--figure {
    background-size: cover;
    height: 20vh;
  }
}

.page-home .homeblocks h3, .page-home .homeblocks p, .page-home .homeblocks a {
  color: #fff;
  font-weight: normal;
}

.page-home .home-chantiers h3 {
  font-size: 2rem;
}

.page-home .home-chantiers > p {
  line-height: 2rem;
}

.page-home .home-chantiers .home-chantiers-map {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  margin-top: 5rem;
}

@media (max-width: 1023px) {
  .page-home .home-chantiers .home-chantiers-map {
    display: block;
  }
}

.page-home .home-chantiers .home-chantiers-map .map {
  -ms-flex: 1;
      flex: 1;
  text-align: center;
}

.page-home .home-chantiers .home-chantiers-map .map img {
  width: 80%;
}

@media (max-width: 1023px) {
  .page-home .home-chantiers .home-chantiers-map .map {
    display: block;
  }
}

.page-home .home-chantiers .home-chantiers-map .suites {
  -ms-flex: 1;
      flex: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  margin-top: 3rem;
}

@media (max-width: 1023px) {
  .page-home .home-chantiers .home-chantiers-map .suites {
    display: block;
  }
}

.page-home .home-chantiers .home-chantiers-map .chantier {
  -ms-flex: 1;
      flex: 1;
}

.page-home .home-chantiers .home-chantiers-map .chantier h4 {
  color: #fff;
  padding: 1rem;
  margin-bottom: 1rem;
}

@media (max-width: 1023px) {
  .page-home .home-chantiers .home-chantiers-map .chantier {
    margin-bottom: 2rem;
  }
}

.page-home .home-chantiers .home-chantiers-map .chantier.chantier-1 {
  color: #ae2c35;
  padding: 0 3rem 0 1rem;
}

.page-home .home-chantiers .home-chantiers-map .chantier.chantier-1 h4 {
  background-color: #ae2c35;
}

@media (max-width: 1023px) {
  .page-home .home-chantiers .home-chantiers-map .chantier.chantier-1 {
    padding: 0;
  }
}

.page-home .home-chantiers .home-chantiers-map .chantier.chantier-2 {
  color: #202020;
}

.page-home .home-chantiers .home-chantiers-map .chantier.chantier-2 h4 {
  background-color: #202020;
}

.page-home .home-block-bottom {
  margin-top: 3rem;
}

.page-home .home-block-bottom a.see-all {
  font-weight: normal;
  text-decoration: underline;
  text-transform: uppercase;
  display: block;
  margin-top: 2rem;
}

.page-home .home-block-bottom .twocolslayout {
  -ms-flex-align: stretch;
      align-items: stretch;
}

.page-home .home-block-bottom h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.page-home .home-block-bottom p {
  line-height: 2rem;
}

.page-home .home-block-bottom .home-actus {
  color: #fff;
  padding: 3rem;
  padding-left: calc(100% - 90%);
}

.page-home .home-block-bottom .home-actus .home-actu {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  margin-top: 2rem;
}

.page-home .home-block-bottom .home-actus .home-actu .actu {
  background-color: #fff;
  padding: 0;
  -ms-flex: 2;
      flex: 2;
}

.page-home .home-block-bottom .home-actus .home-actu .actu::before {
  content: '';
  position: absolute;
  width: 3rem;
  height: 3rem;
  transform: translate(48%, -65%) rotate(45deg);
  background-color: #fff;
  top: 50%;
  right: 0;
}

.page-home .home-block-bottom .home-actus .home-actu .actu .actu-text {
  padding: 2rem;
  color: #555454;
}

.page-home .home-block-bottom .home-actus .home-actu .actu a.more {
  display: block;
  width: 100%;
  background-color: #202020;
  color: #fff;
  padding: 1rem;
  font-size: 1.3rem;
  text-decoration: none;
}

.page-home .home-block-bottom .home-actus .home-actu .actu a.more:hover {
  text-decoration: underline;
}

.page-home .home-block-bottom .home-actus .home-actu .actupic {
  -ms-flex: 1;
      flex: 1;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.page-home .home-block-bottom .home-jobs {
  padding: 4rem;
  padding-right: calc(100% - 90%);
}

.page-home .home-block-bottom .home-jobs ul li {
  margin: 2rem 0;
}

.page-home .home-block-bottom .home-jobs ul li label {
  text-transform: uppercase;
  display: block;
  margin-bottom: 0.5rem;
}

.page-home .home-block-bottom .home-jobs ul li p {
  margin-bottom: 0.5rem;
}

.page-entreprise .int-points {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: center;
      justify-content: center;
}

@media (max-width: 1023px) {
  .page-entreprise .int-points {
    display: block;
  }
}

.page-entreprise .int-points .int-point {
  -ms-flex: 1 auto;
      flex: 1 auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-align: start;
      align-items: flex-start;
  padding: 0 2rem;
}

@media (max-width: 1023px) {
  .page-entreprise .int-points .int-point {
    padding: 0;
    margin-bottom: 2rem;
  }
}

.page-entreprise .int-points .int-point:first-child {
  padding-left: 0;
}

.page-entreprise .int-points .int-point:last-child {
  padding-right: 0;
}

.page-entreprise .int-points .int-point .num {
  font-family: 'Roboto';
  font-weight: 500;
  color: #d3d1d1;
  font-size: 4.5rem;
  line-height: 4rem;
}

.page-entreprise .int-points .int-point .cnt {
  -ms-flex: 1;
      flex: 1;
  padding-left: 0.6rem;
}

.page-entreprise .int-points .int-point .cnt h3 {
  color: #000;
  font-weight: normal;
  font-size: 1.4rem;
  text-transform: uppercase;
}

.page-entreprise .int-points .int-point .cnt h3::first-letter {
  font-size: 1.8rem;
}

.page-entreprise .int-points .int-point .cnt p {
  line-height: 2.2rem;
  font-size: 1.4rem;
  color: #202020;
}

.page-entreprise .int-points .int-point .cnt p::before {
  content: '';
  background-color: #ae2c35;
  width: 6.5rem;
  height: 0.4rem;
  display: block;
  margin: 1rem 0;
}

.page-entreprise .circles {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: center;
      justify-content: center;
}

@media (max-width: 1023px) {
  .page-entreprise .circles {
    display: block;
  }
}

.page-entreprise .circles div.circle {
  -ms-flex: 1;
      flex: 1;
  text-align: center;
}

@media (max-width: 1023px) {
  .page-entreprise .circles div.circle {
    margin-bottom: 2rem;
  }
}

.page-entreprise .circles div.circle label {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: block;
  width: 19rem;
  height: 19rem;
  margin: 1rem auto;
  text-align: center;
  line-height: 19rem;
  font-size: 5rem;
  color: #fff;
  font-weight: bold;
}

.page-entreprise .circles div.circle.circle__1 label {
  background-image: url(../images/entreprise-circle-1.1b830dc7f15fc1316092.png);
}

.page-entreprise .circles div.circle.circle__2 label {
  background-image: url(../images/entreprise-circle-2.90463a25471a632f20d8.png);
}

.page-entreprise .circles div.circle.circle__3 label {
  background-image: url(../images/entreprise-circle-3.b59a3da486173c13a098.png);
}

.page-entreprise .circles div.circle.circle__4 label {
  background-image: url(../images/entreprise-circle-4.362bba3647a189987c75.png);
}

.page-entreprise .circles div.circle p {
  font-size: 1.6rem;
  color: #202020;
  text-align: center;
  display: block;
  margin: 0 auto;
  max-width: 70%;
}

.page-entreprise .chiffres {
  padding-bottom: 5rem;
}

@media (max-width: 1023px) {
  .page-entreprise .chiffres {
    padding-bottom: 2rem;
  }
}

.page-entreprise .chiffres .separ-title {
  padding-top: 4rem;
  color: #fff;
}

.page-entreprise .chiffres .chiffres-blocks {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
}

@media (max-width: 1023px) {
  .page-entreprise .chiffres .chiffres-blocks {
    display: block;
  }
}

.page-entreprise .chiffres .chiffres-blocks div {
  -ms-flex: 1;
      flex: 1;
  text-align: center;
  display: block;
  margin: 0 auto;
  color: #fff;
}

@media (max-width: 1023px) {
  .page-entreprise .chiffres .chiffres-blocks div {
    margin-bottom: 4rem;
  }
}

.page-entreprise .chiffres .chiffres-blocks div label {
  font-size: 10rem;
  display: block;
}

@media (max-width: 1023px) {
  .page-entreprise .chiffres .chiffres-blocks div label {
    font-size: 6rem;
  }
}

.page-entreprise .chiffres .chiffres-blocks div p {
  font-size: 1.6rem;
  display: block;
}

.page-entreprise .chiffres .chiffres-blocks div img {
  margin: 2rem auto 0 auto;
}

@media (max-width: 1023px) {
  .page-entreprise .chiffres .chiffres-blocks div img {
    width: 3rem;
  }
}

.page-entreprise .experts {
  background: url(../images/entreprise-experts.e29bf8ae1928a8f85402.png) center center no-repeat;
  background-size: cover;
  padding: 4rem 0 8rem 0;
}

.page-entreprise .experts .separ-title {
  color: #fff;
}

.page-entreprise .experts .desc {
  text-align: center;
  color: #fff;
  max-width: 38%;
  font-size: 1.4rem;
  line-height: 2.2rem;
}

@media (max-width: 1023px) {
  .page-entreprise .experts .desc {
    max-width: 100%;
  }
}

.page-entreprise .direction {
  margin-bottom: 6rem;
}

.page-entreprise .direction .names {
  font-size: 1.4rem;
  line-height: 2.2rem;
}

.page-suites .head-title h1 {
  color: #fff !important;
}

.page-suites .soustrait {
  text-align: center;
  max-width: 50%;
  font-size: 1.4rem;
}

@media (max-width: 1023px) {
  .page-suites .soustrait {
    max-width: none;
  }
}

.page-suites .logos {
  text-align: center;
}

.page-suites .logos img {
  display: inline-block;
  margin: 0 1%;
  vertical-align: middle;
}

@media (max-width: 1023px) {
  .page-suites .logos img {
    display: block;
    margin: 0 auto;
  }
}

@media (max-width: 1023px) {
  .page-suites .etapes {
    margin-bottom: 2rem;
  }
}

.page-suites .etapes .num {
  font-family: 'Roboto';
  font-weight: 500;
  color: #d3d1d1;
  font-size: 4.5rem;
  line-height: 10rem;
  display: inline;
}

.page-suites .etapes h4 {
  display: inline;
  font-size: 1.4rem;
  text-transform: uppercase;
  position: relative;
}

.page-suites .etapes h4::first-letter {
  font-size: 1.8rem;
}

.page-suites .etapes h4::before {
  content: '';
  background-color: #ae2c35;
  width: 6.5rem;
  height: 0.4rem;
  position: absolute;
  bottom: -1rem;
  left: 0;
}

.page-suites .etapes p {
  margin-top: 2rem;
  line-height: 2.2rem;
  font-size: 1.4rem;
  max-width: 65%;
}

@media (max-width: 1023px) {
  .page-suites .etapes p {
    max-width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
}

.page-suites .etapes .twocolslayout {
  -ms-flex-align: start;
      align-items: flex-start;
}

.page-suites .etapes .twocolslayout:nth-child(even) .twocolslayout__item--body {
  padding-left: 4rem;
}

@media (max-width: 1023px) {
  .page-suites .etapes .twocolslayout:nth-child(even) .twocolslayout__item--body {
    padding-left: 0;
  }
}

.page-realisations .reals ul {
  display: inline-block;
  width: 100%;
}

.page-realisations .reals ul li {
  float: left;
  width: 31.3%;
  margin: 1%;
  height: 32rem;
  position: relative;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 1023px) {
  .page-realisations .reals ul li {
    float: none;
    width: 100%;
    margin-bottom: 2rem;
  }
}

.page-realisations .reals ul li a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
}

.page-realisations .reals ul li::after {
  background-position: center 20%;
}

.page-realisations .reals ul li:hover .real-arrow {
  border-bottom-color: #fff;
}

.page-realisations .reals ul li:hover .real-infos {
  background-color: #fff;
}

.page-realisations .reals ul li .real-arrow {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(0, 0, 0, 0);
  border-bottom-color: #f1eeee;
  border-width: 2rem;
  margin-left: -2rem;
}

.page-realisations .reals ul li .real-infos {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  text-align: center;
  background-color: #f1eeee;
}

.page-realisations .reals ul li .real-infos > label {
  display: block;
  font-size: 1.4rem;
  color: #000;
  text-transform: uppercase;
  margin: 1rem 0 0.2rem 0;
}

.page-realisations .reals ul li .real-infos > span {
  display: block;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.page-realisations .showmore {
  margin: 2rem auto;
}

.page-realisation .pins {
  width: 30%;
}

@media (max-width: 1023px) {
  .page-realisation .pins {
    width: 100%;
  }
}

.page-realisation .pins .real-pins {
  -ms-flex-pack: center;
      justify-content: center;
}

.page-realisation .videoChantier {
  display: block;
  margin: 0 auto;
  width: 70%;
}

.page-realisation .button.back, .page-realisation .page-actualites .actus ul li > div a.back, .page-actualites .actus ul li > div .page-realisation a.back {
  display: inline-block;
  width: auto;
  margin: 2rem auto;
  margin-left: 50%;
  transform: translateX(-50%);
}

.real-pins {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  width: 100%;
  padding-bottom: 1rem;
}

.real-pins > div {
  -ms-flex: 1;
      flex: 1;
  text-align: center;
  position: relative;
}

.real-pins > div span {
  display: block;
  color: #ae2c35;
  max-width: 80%;
  margin: 0 auto;
}

.real-pins > div:nth-child(2)::before {
  content: '';
  position: absolute;
  height: 60%;
  width: 1px;
  background-color: #e6e6e6;
  top: 0;
  left: 0;
}

.real-pins > div:nth-child(2)::after {
  content: '';
  position: absolute;
  height: 60%;
  width: 1px;
  background-color: #e6e6e6;
  top: 0;
  right: 0;
}

.page-actualites .actus ul {
  display: inline-block;
  width: 100%;
}

.page-actualites .actus ul li {
  float: left;
  width: 31.3%;
  margin: 1%;
  height: 32rem;
  position: relative;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 1023px) {
  .page-actualites .actus ul li {
    float: none;
    width: 100%;
    margin-bottom: 2rem;
  }
}

.page-actualites .actus ul li::before {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: all ease-out 250ms;
}

.page-actualites .actus ul li > div {
  position: absolute;
  z-index: 2;
  top: auto;
  left: 2rem;
  right: 2rem;
  bottom: 2rem;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.8);
  transition: all ease-out 250ms;
}

.page-actualites .actus ul li > div label {
  display: block;
  font-size: 1.6rem;
  font-weight: bold;
}

.page-actualites .actus ul li > div span {
  display: block;
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.page-actualites .actus ul li > div p {
  display: block;
  font-size: 1.4rem;
}

.page-actualites .actus ul li > div a {
  background-color: #000;
  padding: 0.25rem 2rem;
  font-size: 1.3rem;
  text-transform: uppercase;
  margin: 0.7rem auto;
}

.page-actualites .actus ul li:hover::before {
  background-color: rgba(0, 0, 0, 0.4);
}

.page-actualites .actus ul li:hover div {
  background-color: white;
}

.page-actualites .actus .showmore {
  margin: 2rem auto;
}

.page-actualite-single .head-title h1 {
  margin-bottom: 0;
}

.page-contact .head-title h1 {
  margin-bottom: 0;
}

.page-contact .contact-form p {
  text-align: center;
  font-size: 1.4rem;
}

.page-offres .intro p {
  color: #ae2c35;
  font-size: 1.4rem;
  text-align: center;
}

.page-offres .offres {
  text-align: center;
  font-size: 1.4rem;
}

.page-offres .offres ul li {
  margin: 3rem auto 5rem auto;
}

.page-offres .offres ul li label {
  font-weight: bold;
  text-decoration: underline;
}

.page-offres .offres ul li p {
  margin: 0.5rem auto;
}

.page-offres .offres ul li .offre-description {
  width: 70%;
  line-height: 2rem;
}

.page-offres .offres ul li .button, .page-offres .offres .page-actualites .actus ul li > div a, .page-actualites .actus .page-offres .offres ul li > div a {
  background-color: #000;
}

.page-offres .offre-form button[type="submit"] {
  background-color: #000;
  font-size: 1.6rem;
}

.page-offres .offre-form p {
  text-align: center;
  font-size: 1.4rem;
}

.form-container {
  width: 60%;
  margin: 4rem auto;
}

@media (max-width: 1023px) {
  .form-container {
    width: 100%;
  }
}

.form-container input[type="text"],
.form-container input[type="email"],
.form-container input[type="tel"] {
  display: block;
  margin: 1rem auto;
  border: solid 1px #a1b2be;
  width: 100%;
  font-size: 1.4rem;
  line-height: 3.2rem;
  text-align: center;
}

.form-container input[type="text"]:-ms-input-placeholder,
.form-container input[type="email"]:-ms-input-placeholder,
.form-container input[type="tel"]:-ms-input-placeholder {
  color: #ae2c35;
}

.form-container input[type="text"]::placeholder,
.form-container input[type="email"]::placeholder,
.form-container input[type="tel"]::placeholder {
  color: #ae2c35;
}

.form-container textarea {
  display: block;
  margin: 1rem auto;
  border: solid 1px #a1b2be;
  width: 100%;
  font-size: 1.4rem;
  line-height: 1.8rem;
  text-align: center;
}

.form-container textarea:-ms-input-placeholder {
  padding-top: 1rem;
  color: #ae2c35;
}

.form-container textarea::placeholder {
  padding-top: 1rem;
  color: #ae2c35;
}

.form-container select {
  width: 100%;
  text-align: center;
  text-align-last: center;
  background-color: #ae2c35;
  color: #fff;
  font-size: 1.4rem;
  height: 3.4rem;
}

.form-container .attachlabel {
  display: inline;
  color: #ae2c35;
  font-size: 1.4rem;
}

.form-container .mandatorystar {
  color: #ae2c35;
  font-size: 1.4rem;
  display: block;
  margin: 1rem 0;
}

.form-result {
  display: block;
  margin: 2rem auto;
}

.form-result .form-errors {
  color: red;
  font-size: 1.4rem;
  line-height: 1.8rem;
}

.form-result .form-confirmation {
  color: #000;
  font-size: 1.4rem;
  line-height: 1.8rem;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  img:not(.noscale) {
    transform: scale(0.5);
  }
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-leave,
.fade-enter-to {
  opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 300ms;
}

/* base */
.stories {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  z-index: 1;
}

/* moving */
.stories-move {
  transition: all 600ms ease-in-out 50ms;
}

/* appearing */
.stories-enter-active {
  transition: all 400ms ease-out;
}

/* disappearing */
.stories-leave-active {
  transition: all 200ms ease-in;
  position: absolute;
  z-index: 0;
}

/* appear at / disappear to */
.stories-enter,
.stories-leave-to {
  opacity: 0;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity .3s ease;
}

.modal-rel {
  position: relative;
}

.modal-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.modal-container {
  position: absolute;
  height: auto;
  margin: 0;
  padding: 0;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all .3s ease;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center center;
}

@media (max-width: 1023px) {
  .modal-container {
    width: 90%;
  }
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body video {
  width: 100%;
  height: auto;
}

.modal-default-button {
  position: absolute;
  right: -2rem;
  top: -2rem;
  z-index: 999;
}

/*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.slider a.next, .slider a.prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  background-position: center center;
  background-repeat: no-repeat;
  width: 5rem;
  height: 5rem;
  z-index: 10;
  cursor: pointer;
}

.slider a.prev {
  left: 2rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAQCAYAAAArij59AAAAhklEQVQokXWRUQ3DMAwFr9EIhEIphEIplEIHoVgyCMNSLC2Et4+5qhXZlqxE8km+JyOpSSLrAixAJ6li75ZCtuLUv76Sql9xf5qkw6DDQ16oRlBk3j2UxdsNWkrgXYHmU/ienUcfHXzcPqbYouENrHpqH4VfwGw6b+CT3SIcAkySKnBl1/wBMXMPNNzfu5EAAAAASUVORK5CYII=);
  transform: translateY(-50%) rotate(180deg);
}

.slider a.next {
  right: 2rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAQCAYAAAArij59AAAAhklEQVQokXWRUQ3DMAwFr9EIhEIphEIplEIHoVgyCMNSLC2Et4+5qhXZlqxE8km+JyOpSSLrAixAJ6li75ZCtuLUv76Sql9xf5qkw6DDQ16oRlBk3j2UxdsNWkrgXYHmU/ienUcfHXzcPqbYouENrHpqH4VfwGw6b+CT3SIcAkySKnBl1/wBMXMPNNzfu5EAAAAASUVORK5CYII=);
}

.slider .slide {
  transition: 0.6s ease;
  transform: translate(-100%, 0);
}

.slider .slide.active {
  transform: translate(0, 0);
}

.slider .slide.active ~ .slide {
  transform: translate(100%, 0);
}
